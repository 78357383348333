{
  "name": "fooswars",
  "version": "6.23.2",
  "scripts": {
    "ng": "ng",
    "start": "ng serve --port 4258",
    "build": "ng build --configuration production",
    "test": "ng test",
    "lint": "ng lint --quiet",
    "e2e": "ng e2e",
    "analyze": "ng build --configuration production --stats-json && webpack-bundle-analyzer dist/stats.json",
    "preinstall": "npx only-allow pnpm",
    "format": "prettier --write .",
    "check-format": "prettier --check ."
  },
  "private": true,
  "dependencies": {
    "@angular/animations": "^18.2.8",
    "@angular/cdk": "^18.2.9",
    "@angular/common": "^18.2.8",
    "@angular/compiler": "^18.2.8",
    "@angular/core": "^18.2.8",
    "@angular/forms": "^18.2.8",
    "@angular/material": "^18.2.9",
    "@angular/material-luxon-adapter": "^18.2.9",
    "@angular/platform-browser": "^18.2.8",
    "@angular/platform-browser-dynamic": "^18.2.8",
    "@angular/platform-server": "^18.2.8",
    "@angular/router": "^18.2.8",
    "@angular/service-worker": "^18.2.8",
    "@ctrl/ngx-emoji-mart": "^9.2.0",
    "@microsoft/signalr": "^8.0.7",
    "@ngrx/effects": "^18.1.0",
    "@ngrx/operators": "^18.1.0",
    "@ngrx/store": "^18.1.0",
    "@ngrx/store-devtools": "^18.1.0",
    "@ngx-translate/core": "^15.0.0",
    "@ngx-translate/http-loader": "^8.0.0",
    "@sweetalert2/ngx-sweetalert2": "^12.4.0",
    "@sweetalert2/theme-dark": "^5.0.15",
    "@swimlane/ngx-charts": "^20.4.1",
    "@types/luxon": "^3.4.2",
    "bootstrap": "^5.3.2",
    "d3-shape": "^3.2.0",
    "luxon": "^3.4.4",
    "ngx-image-cropper": "^7.0.3",
    "ngx-infinite-scroll": "^18.0.0",
    "ngx-translate-messageformat-compiler": "^7.0.0",
    "rxjs": "^7.8.1",
    "sweetalert2": "^11.10.5",
    "tslib": "^2.6.2",
    "ua-parser-js": "^1.0.36",
    "zone.js": "~0.14.10"
  },
  "devDependencies": {
    "@angular-devkit/build-angular": "^18.2.9",
    "@angular-eslint/builder": "^18.3.1",
    "@angular-eslint/eslint-plugin": "^18.3.1",
    "@angular-eslint/eslint-plugin-template": "^18.3.1",
    "@angular-eslint/schematics": "^18.3.1",
    "@angular-eslint/template-parser": "^18.3.1",
    "@angular/cli": "^18.2.9",
    "@angular/compiler-cli": "^18.2.8",
    "@angular/language-service": "^18.2.8",
    "@ngrx/schematics": "^18.1.0",
    "@types/d3-shape": "^3.1.6",
    "@types/jasmine": "^5.1.0",
    "@types/jasminewd2": "^2.0.11",
    "@types/node": "^20.8.4",
    "@types/ua-parser-js": "^0.7.37",
    "@typescript-eslint/eslint-plugin": "^8.0.1",
    "@typescript-eslint/parser": "^8.0.1",
    "eslint": "^9.9.1",
    "eslint-config-prettier": "^9.1.0",
    "eslint-plugin-prettier": "^5.2.1",
    "jasmine-core": "~5.1.1",
    "jasmine-spec-reporter": "~7.0.0",
    "karma": "~6.4.2",
    "karma-chrome-launcher": "~3.2.0",
    "karma-cli": "~2.0.0",
    "karma-coverage": "^2.2.1",
    "karma-coverage-istanbul-reporter": "~3.0.3",
    "karma-jasmine": "~5.1.0",
    "karma-jasmine-html-reporter": "^2.1.0",
    "karma-junit-reporter": "^2.0.1",
    "prettier": "^3.3.3",
    "prettier-eslint": "^16.3.0",
    "typescript": "~5.5.3",
    "webpack-bundle-analyzer": "^4.9.1"
  }
}
