import { Injectable } from '@angular/core';
import { HubConnection, HubConnectionBuilder, HubConnectionState } from '@microsoft/signalr';
import { Store, select } from '@ngrx/store';
import {
    BellNotificationCountModel,
    CreateMatchModel,
    GetMatchAchievementModel,
    GetMatchCommentEmojiModel,
    GetMatchEmojiModel,
    MatchInvitationCommentModel,
    MatchInvitationModel,
} from 'models';
import { Observable, Subject, interval } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { matchesActions } from 'store';
import { loadMatchInvitationsSuccess } from 'store/sidebar/sidebar.actions';
import { AppState, sidebarActions } from '../store';
import { Session } from './session.service';
import { TokenService } from './token.service';
import { EMPTY } from './utils';

export interface IEmojiAdded {
    idMatch: number;
    idPlayer: number;
    type: string;
    count: number;
}
export interface IMatchCommentChanged {
    idMatch: number;
    idPlayer: number;
    count: number;
}

export interface ICommentEmojiAdded {
    commentId: number;
    playerId: number;
    emojies: Array<GetMatchCommentEmojiModel>;
}

@Injectable({
    providedIn: 'root',
})
export class SignalRService {
    private _connection: HubConnection;

    private _newMatchSub = new Subject<void>();

    private _emojiAdded = new Subject<IEmojiAdded>();
    private _emojiChanged = new Subject<{ data: Array<GetMatchEmojiModel>; idMatch: number }>();
    private _matchCommentChanged = new Subject<IMatchCommentChanged>();
    private _commentEmojiAdded = new Subject<ICommentEmojiAdded>();
    private _achievementsChanged = new Subject<{ idMatch: number; achievements: Array<GetMatchAchievementModel> }>();

    public newMatch: Observable<void>;

    public emojiAdded$: Observable<IEmojiAdded>;
    public emojiChanged$: Observable<{ data: Array<GetMatchEmojiModel>; idMatch: number }>;
    public matchCommentsChanged$: Observable<IMatchCommentChanged>;
    public commentEmojiAdded$: Observable<ICommentEmojiAdded>;
    public achievementsChanged$ = this._achievementsChanged.asObservable();

    public state$ = new Observable<HubConnectionState>();

    public get state() {
        return this._connection.state;
    }

    public get connectionId() {
        return this._connection.connectionId;
    }

    constructor(
        private readonly store: Store<AppState>,
        private readonly session: Session,
        private readonly tokenService: TokenService,
    ) {
        this.newMatch = this._newMatchSub.asObservable();
        this.emojiAdded$ = this._emojiAdded.asObservable();
        this.matchCommentsChanged$ = this._matchCommentChanged.asObservable();
        this.commentEmojiAdded$ = this._commentEmojiAdded.asObservable();
        this.emojiChanged$ = this._emojiChanged.asObservable();

        this._connection = new HubConnectionBuilder()
            .withUrl('/signalr/notifications', {
                accessTokenFactory: () => {
                    return this.tokenService.getAccessToken();
                },
            })
            .withAutomaticReconnect()
            .build();
        this.wireUpEvents();
        this.state$ = interval(1000).pipe(map(() => this._connection.state));

        this.session.userInitialized.subscribe(() => {
            this.connect().catch(EMPTY);
        });
    }

    private connect() {
        if (this._connection.state === HubConnectionState.Connected) {
            return this._connection.stop().then(() => this._connection.start());
        } else {
            return this._connection.start();
        }
    }

    public checkConnection() {
        if (this._connection.state === HubConnectionState.Disconnected) {
            this._connection.start().catch(EMPTY);
        }
    }

    private wireUpEvents() {
        this._connection.on('OnNewMatch', () => {
            this._newMatchSub.next();
        });
        this._connection.on('OnSetOngoingMatches', (res) => {
            this.store.dispatch(matchesActions.setOngoingMatches({ matches: res }));
        });

        this._connection.on('OnMatchInviteChange', (invitations: Array<MatchInvitationModel>) => {
            this.store.dispatch(loadMatchInvitationsSuccess({ payload: invitations }));
            this.store.dispatch(sidebarActions.getNotificationCount());
        });

        this._connection.on('OnMatchEmojiAdded', (matchId, playerId, type, count) => {
            if (this.session.user.idPlayer !== playerId) {
                this._emojiAdded.next({ count: count, idMatch: matchId, idPlayer: playerId, type: type });
            }
        });
        this._connection.on('OnMatchEmojiChanged', (idMatch, idPlayer, data) => {
            this._emojiChanged.next({ data: data as Array<GetMatchEmojiModel>, idMatch });
        });
        this._connection.on('OnMatchCommentsChanged', (idMatch, idPlayer, count) => {
            this._matchCommentChanged.next({ count, idMatch, idPlayer });
        });
        this._connection.on('OnMatchCommentEmojiAdded', (commentId, playerId, emojies: Array<GetMatchCommentEmojiModel>) => {
            if (this.session.user.idPlayer !== playerId) {
                this._commentEmojiAdded.next({ commentId: commentId, playerId: playerId, emojies: emojies });
            }
        });
        this._connection.on('OnAchievementsChanged', (idMatch: number, achievements: Array<GetMatchAchievementModel>) => {
            this._achievementsChanged.next({ idMatch, achievements });
        });
        this._connection.on('OnMatchInviteCommentsChanged', (idMatchInvitation, comments: Array<MatchInvitationCommentModel>) => {
            this.store.dispatch(sidebarActions.matchInvitationCommentsChanged({ id: idMatchInvitation, comments }));
        });

        this._connection.on('OnUpdateBellNotifications', (count: BellNotificationCountModel) => {
            if (count) {
                this.store.dispatch(sidebarActions.getNotificationCountSuccess(count));
            } else {
                this.store.dispatch(sidebarActions.getNotificationCount());
            }
        });

        this.store
            .pipe(
                select((x) => x.matches.active),
                filter((x) => !!x && x.started),
            )
            .subscribe((x) => {
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                const { restored, version, settings, ...match } = x;
                const { gameMode } = settings;
                const active: CreateMatchModel = { ...match, elapsedSeconds: 0, settings: { gameMode } };
                this.invokeUpdateOngoingMatch(active);
            });
    }

    public invokeUpdateOngoingMatch(match: CreateMatchModel) {
        if (this._connection.state === HubConnectionState.Connected) {
            this._connection.invoke('UpdateOngoingMatch', match).catch(EMPTY);
        }
    }
    public invokeClearOngoingMatch(uniqueId: string) {
        if (this._connection.state === HubConnectionState.Connected) {
            this._connection.invoke('ClearOngoingMatch', uniqueId).catch(EMPTY);
        }
    }

    public invokeUserAuthenticated() {
        if (this._connection.state === HubConnectionState.Connected) {
            this._connection.invoke('UserAuthenticated').catch(EMPTY);
        }
    }
}
